import React from "react"

import Home from "../layouts/home"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Home>
    <SEO title="404: Not found" />
    <div className='title'>NOT FOUND</div>
    <p>Oops. This URL doesn't go anywhere. Oh well, there are worse things in life.</p>
  </Home>
)

export default NotFoundPage
